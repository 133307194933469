body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
}
header {
  background-color: #000;
  min-height: 90px;
  text-align: center;
  position: relative;
}
.top {
  height: 120px;
  overflow: hidden;
  position: relative;
}
.content-welcome {
  position: absolute;
  top: 18px;
  z-index: 999;
  color: #fff;
  width: 100%;
}
.back {
  width: 100%;
}
.logo {
  max-width: 50px;
  text-align: center;
  background-color: #fff;
  /*margin: 10px;
  position: absolute;
  z-index: 999;*/
}

a.rs-nav-item.rs-nav-item-active {
  color: #ff2850 !important;
}
.rs-nav-subtle .rs-nav-item.rs-nav-item-active:before {
  background-color: #ff2850 !important;
}
a.rs-nav-item {
  width: 120px;
  text-align: center;
  font-weight: bold;
}
.rs-nav.rs-nav-subtle.rs-nav-horizontal {
  text-align: center;
}
.rs-nav-subtle.rs-nav-horizontal .rs-nav-bar {
  border-top: 2px solid #0000003b !important;
}
.name {
  display: inline;
}
.shadow-box {
  box-sizing: border-box;
  box-shadow: 0 1px 10px rgb(0 0 0 / 10%);
}
.inter {
  padding: 15px 10px;
}
.info {
  margin-bottom: 25px;
}
.black {
  background-color: #000;
}
.white {
  background-color: #fff;
}
/*.container{
  border: solid 1px #ff2850 !important;
}
.row{
  border: solid 1px #ff2850 !important;
}*/

button{
  width: 100%;
}
.semi-bold{
  font-weight: 600;
}