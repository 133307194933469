$FirstColor: '';
$SecondColor: '';

.red {
    color: #f00;
    margin: 0;
}
.green {
    color: #0f5132;
    margin: 0;
}
.bg-red {
    text-decoration: none !important;
    background-color: #f00;
    color: #fff;
    border: 1px solid #f00;
    position: relative;
    border-radius: 0;
    margin: 10px 0;

    &::after,
    &::before {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        border: inherit;
        transition: all .5s;
    }

    &::before {
        top: -7px;
        left: -7px;
        border-width: 1px 0 0 1px;
    }

    &::after {
        bottom: -7px;
        right: -7px;
        border-width: 0 1px 1px 0;
    }

    &:hover {
        color: #fff;
        font-weight: 600;

        &::before,
        &::after {
            width: calc(100% + 20px);
            height: calc(100% + 20px);
        }

    }
}
.bg-black {
    text-decoration: none !important;
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
    position: relative;
    border-radius: 0;
    margin-top: 15px;
    &:hover{
        color: #ccc;
    }
}
.bg-grey {
    text-decoration: none !important;
    background-color: #ccc;
    color: #fff;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 0;
    margin-top: 15px;
    &:hover{
        color: #000;
    }
}