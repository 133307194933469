.menu-item > a, .menu-item > a:hover, .menu-item > a:active, .menu-item > a:focus {
	color: black;
}
.active-menu {
	background-color: black !important;
}
.active-menu > a, .active-menu > a:hover, .active-menu > a:active, .active-menu > a:focus {
	color: white;
}

.table tr th {
	font-weight: 500;
}