@import url('./Colors.scss');

.log {
    .texto {
        background-color: #000;
        padding: 0 15%;
        color: #fff;

        @media (max-width: 796px) {
            padding: 15% 4%;
        }

        h2 {
            font-weight: bold;
            font-size: 36px;
        }

        p {}
    }

    .login {
        width: 100%;
        max-width: 560px;
        text-align: initial;
        margin: 0 auto;
        position: relative;
        margin-right: 0;
        padding: 0 15px;

        &.full {
            max-width: 780px;
        }

        @media (max-width: 796px) {
            max-width: 100%;
        }

        h2 {
            text-transform: uppercase;
            margin: 15px 0;
            font-weight: bold;
        }

        form {
            button {
                width: 100%;
                text-transform: uppercase;
            }
        }
    }
}

footer {
    background-color: #000;
    padding: 15px 0;
    color: #fff;
    border-top: solid 2px #fff;

    span {
        font-size: 14px !important;
    }
}

.rs-form-control-wrapper {
    display: block !important;

    .rs-input {
        width: 100% !important;
    }
}

.rs-picker-toggle-wrapper {
    width: 100%;
}


.info {
    label {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 7px;
    }

    p {
        font-size: 14px;
    }
}

#credencial {
    background: url('https://mi.csdcolocolo.cl/img/credencial.png');
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 284px;
    margin: 0;
    max-width: 460px;


    #content-credencial {
        height: 150px;

        .laimagen {
            width: 100px;
            margin-left: 30px;
            height: 100px;
        }

        .lainfo {
            margin-left: 23px;
            margin-top: -9px;
            font-size: 20px;
            line-height: 29px;
            width: 100%;
            display: inline-block;
            height: 110px;

            .name {
                max-width: 100%;
                height: auto;
                width: 100%;
                display: block;
                margin-bottom: 10px;

                p,
                strong {
                    line-height: normal;
                }
            }

            .rut,
            .comuna {
                width: 100%;
                font-size: 13px;
                line-height: normal;
                font-weight: bold;
            }
        }
    }
}

.btn-buy {
    width: 100%;

    &:hover {
        background-color: #f00 !important;
        text-decoration: none;
    }
}

.title {
    margin-bottom: 20px;

    strong {
        border-bottom: solid 2px #f00;
    }
}

.w-100 {
    width: 33px !important;
}

.btn {
    width: 96.5%;

    &.full {
        width: 100%;
    }

    /*font-weight: bold;
    letter-spacing: 4px;*/
}

.close-sesion {
    cursor: pointer;
    color: #fff;
    text-decoration: underline;
}

.link {
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;

    &.tx-white {
        color: #fff !important;
    }

    &.tx-dark {
        color: #000;
    }

    &:hover {
        color: #ccc !important;
    }

}

.table,
tr,
td {
    border: none !important;
}

.img-default {
    max-width: 200px;
    display: block;
}

html,
body,
#root {
    height: 84vh;
}
footer{
    height: 16vh;
}